import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { SubHeading } from './components';
import { Formik, useField, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  USER_ROLES,
  DOCUMENT_ALLOW_PASTE,
  MSAR_FUND,
} from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import {
  Bank,
  Document,
  DocumentType,
  FileType,
  individuals_Poa_nonIndividuals_Documents,
} from '../../redux-store/types/api-types';
import {
  getMSARDocuments,
  updateApplication,
  uploadSignedDocs,
} from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { documentDetailsSchema } from '../../utils/schema';
import { showError } from '../../redux-store/actions/auth';
import {
  applicationComparison,
  applyRoleBasedStatus,
  approvedApplicationDownloadStatusCheck,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import MFCheckbox from '../../lib/formik/Checkbox';
import { LoadingButton } from '@mui/lab';
import { ApplicationDownload } from '../../redux-store/actions/onBoarding';
import { Location } from 'history';
import { reportDownload } from '../Reports/report-utils';
import { FileUpload, documentDetails } from './documentDetails';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

export type documentProps = {
  documentType: string;
  documentName: string;
  documentsList: documentDetails[];
  multipleFiles: string;
  required: string;
  options?: string[];
  infoText?: string[];
};

export interface Values {
  documentDeclaration: boolean;
  signedDocuments: documentProps[];
  saveType: string;
  [key: string]: documentProps[] | string | undefined | string[] | Bank[] | boolean;
}
export const document_object = {
  documentId: '',
  isActive: true,
};

export const documentDeclaration = `I declare that I am responsible for verifying the accuracy and completeness of the documents I upload. I confirm that all information is accurate and complete before submitting. I understand that once the documents are submitted, they cannot be replaced, modified, or deleted.`;

export const getDocumentTitle: { [key: string]: string } = {
  admission_deed: 'Admission Deed',
  consent_form: 'Consent Form',
  poa: 'POA',
};

export default function PhysicalSigningDocuments({
  location,
}: {
  location: Location<{ id: string }>;
}): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [approvedApplicationDownloading, setApprovedApplicationDownloading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUpload, setDocumentUpload] = useState(false);
  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getMSARDocuments())) as unknown as Document;
        setDocumentsData(response as Document);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const {
    signedDocuments = [],
    documentDeclaration: declaration = false,
    status = '',
  } = application || {};
  const docData = MSAR_FUND;
  const initialValues: Values = {
    signedDocuments:
      typeof documentsData !== 'undefined'
        ? ((documentsData as Document)[docData] || []).map(
            (doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
              const { documentType, documentName, multipleFiles, required, options, infoText } =
                doc;
              const docsOfCurrentDocType = signedDocuments
                .filter((doc) => doc.documentType === documentType)
                .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
              return {
                documentType: documentType,
                documentName: documentName,
                documentsList: docsOfCurrentDocType.length
                  ? docsOfCurrentDocType.map((doc, ind) => {
                      const {
                        id,
                        documentName = '',
                        documentType = '',
                        documentId = '',
                        isActive = true,
                        file = undefined,
                      } = doc;
                      return {
                        id,
                        documentType,
                        documentName,
                        documentId,
                        isActive,
                        file,
                        infoText,
                        options,
                        required,
                        uniqueKey: (index.toString() + '-' + ind.toString()) as string,
                      };
                    })
                  : [
                      {
                        documentType,
                        documentName,
                        required,
                        infoText,
                        options,
                        uniqueKey: (index.toString() + '-' + '0') as string,
                        ...document_object,
                      },
                    ],
                required,
                multipleFiles,
                options,
                infoText,
              };
            }
          )
        : [],
    documentDeclaration: declaration,
    saveType: 'save and proceed',
  };

  const updateSignedDocs = (signedDocs: documentProps[]): DocumentType[] => {
    const finalDocs = signedDocs.map((doc) => {
      return doc.documentsList.filter((d) => d.documentId);
    });
    return finalDocs.flat().map((doc) => {
      const { uniqueKey, required, ...rest } = doc;
      return rest;
    }) as DocumentType[];
  };

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        currentStep,
        status,
        applicationNumber,
        signedDocuments: existingSignedDocuments,
        documentDeclaration: existingDocumentDeclaration,
      } = application || {};
      const { signedDocuments, documentDeclaration, saveType } = values;
      signedDocuments?.forEach((_doc) => {
        _doc.documentsList?.forEach((doc) => {
          if (!doc.documentId) {
            throw `Please Select ${getDocumentTitle[doc.documentType]}`;
          }
        });
      });
      const updatedSignedDocs = updateSignedDocs(signedDocuments);
      if (updatedSignedDocs.length === 3 && !documentDeclaration) {
        throw `Please select document declaration`;
      }
      const checkApplication = applicationComparison(
        {
          ...application,
          signedDocuments: existingSignedDocuments
            ?.map((document: any) => {
              const {
                documentData,
                filename,
                updatedAt,
                createdAt,
                updatedBy,
                createdBy,
                ...rest
              } = document;
              return { ...rest };
            })
            .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
          documentDeclaration: existingDocumentDeclaration,
        },
        {
          ...application,
          signedDocuments: signedDocuments
            ?.map((document: any) => {
              const { documentData, infoText, options, ...rest } = document;
              return { ...rest };
            })
            .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
          documentDeclaration: documentDeclaration,
        }
      );

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          uploadSignedDocs({
            body: {
              // ...application,
              signedDocuments: updatedSignedDocs,
              documentDeclaration: documentDeclaration,
            },
            applicationId: id,
          })
        );
        history.push(`/applications`);
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, documentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <SubHeading>Action Required: Download and Upload Signed Documents</SubHeading>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <LoadingButton
              loadingPosition="start"
              // type="button"
              variant="contained"
              sx={{
                color: 'common.white',
                lineHeight: 1.5,
                fontSize: 14,
                px: 6,
                m: 1,
                ml: { xs: 0 },
              }}
              disabled={approvedApplicationDownloading}
              onClick={async () => {
                try {
                  if (approvedApplicationDownloadStatusCheck(status || '')) {
                    setApprovedApplicationDownloading(true);
                    const res = (await dispatch(ApplicationDownload(id))) as unknown as any;
                    reportDownload(res as unknown as Blob, `${id}.pdf`);
                    setApprovedApplicationDownloading(false);
                    return;
                  }
                } catch (e) {
                  setApprovedApplicationDownloading(false);
                  console.error((e as Error).message);
                }
              }}>
              Download Approved Application
            </LoadingButton>
          </Grid>
          {values.signedDocuments?.map((document, doc_index) => {
            const {
              documentType,
              documentName,
              documentsList = [],
              multipleFiles,
              options,
              infoText,
              required,
            } = document;
            return (
              <React.Fragment key={doc_index}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      mt: '59px',
                    }}>
                    {documentName} {'*'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {documentsList.map((doc, index) => (
                    <React.Fragment key={doc.uniqueKey}>
                      <FileUpload
                        onFileChange={(value, documentId, removeFile) => {
                          const { file, ...rest } =
                            values.signedDocuments[doc_index].documentsList[index];
                          setFieldValue(`signedDocuments.${doc_index}.documentsList.${index}`, {
                            ...rest,
                            documentId,
                            ...(!removeFile && { file }),
                          });
                        }}
                        name={`signedDocuments.${doc_index}.documentsList.${index}.documentId`}
                        documentData={doc}
                        disabled={isFieldDisabled}
                        onFileUpload={handleUpload}
                        onFileUploaded={handleUploaded}
                      />
                    </React.Fragment>
                  ))}
                </Grid>
              </React.Fragment>
            );
          })}

          <MFCheckbox
            name="documentDeclaration"
            label={documentDeclaration}
            sx={{
              letterSpacing: '1px',
              textAlign: 'justify',
              marginTop: 5,
              display: 'flex',
              alignItems: 'flex-start',
            }}
          />
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              variant="contained"
              sx={{
                px: 6,
                color: 'common.white',
                lineHeight: 1.5,
                fontSize: 14,
                m: 1,
                ml: { xs: 0 },
                mt: 5,
              }}
              loading={loading}
              disabled={documentUpload}>
              Save and Submit
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
